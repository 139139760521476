<script lang="ts">
  import Text from '$lib/components/text/text.svelte';
  import { onMount } from 'svelte';

  type OperationalStatus = {
    description: string;
    indicator: 'none' | 'minor' | 'major' | 'critical';
  };

  let status: OperationalStatus = {
    description: 'All Systems Operational',
    indicator: 'none',
  };

  const fetchStatus = async () => {
    try {
      const response = await fetch(
        'https://status.temporal.io/api/v2/status.json',
      );
      const statusFromApi = await response.json();
      if (statusFromApi.status) {
        status = statusFromApi.status;
      }
    } catch (e) {
      // If status fetch fails, is the world ending? Probs just Atlassian down and we'd need to manually change it.
    }
  };

  onMount(() => {
    fetchStatus();
  });
</script>

<a href="https://status.temporal.io/" class="status-badge {status.indicator}">
  <Text variant="mono-16">{status.description.toUpperCase()}</Text>
</a>

<style lang="postcss">
  .status-badge {
    @apply flex border border-solid p-2 font-mono rounded max-lg:justify-center;

    &.none {
      @apply border-mint text-mint;
    }
    &.minor {
      @apply border-mustard text-mustard;
    }
    &.major {
      @apply border-yellow-900 text-yellow-900;
    }
    &.critical {
      @apply border-scarlet text-scarlet;
    }
  }
</style>
